<template>
  <q-menu
    id="bodyTopMainAppDownloadIphonePopup"
    :anchor="$attrs.platform === 'mobile'
      ? 'top middle'
      : 'top start'"
    :class="appDownload.layout"
    :offset="[20,0]"
    auto-close
    class="no-padding"
    no-focus
    no-refocus
    self="top middle"
    style="cursor:pointer;"
    transition-hide="jump-up"
    transition-show="jump-down"
  >
    <q-list :class="appDownload.iPhonePopup">
      <p class="text-body_small no-margin no-padding">
        {{ $attrs.iPhonePopup.description }}
      </p>
      <div class="row q-pt-sm">
        <p class="text-caption_large text-color_lightgray3 no-margin no-padding">
          {{ $attrs.iPhonePopup.hire }}
        </p>
        <p class="text-caption_large_bold q-pl-xs no-margin text-color_vitoBlue"
           onmouseover="this.style.cursor='pointer'"
           @click="openURL(getRecruitsURL)">
          {{ $attrs.iPhonePopup.hireText }}
        </p>
      </div>
    </q-list>
  </q-menu>
</template>

<script setup>
// Imports

import { openURL } from 'quasar'
import { useStore } from 'vuex'

// Props

// Emits

// Variables

const store = useStore()

// Responses

// Methods

const getRecruitsURL = store.getters.getRecruitsURL

// Lifecycles

</script>

<style module="appDownload">

.layout {
  border-radius: 14px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, .08);
}

.iPhonePopup {
  min-width: 200px;
  max-width: 354px;
  padding: 16px 20px;
}
</style>
